import { isMobile } from '../utils/index'
export default {
  address: '新北市淡水區中山北路三段90號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14441.41951953333!2d121.43573446824783!3d25.191252038122155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442baa83ff102a3%3A0x24daeba041b51f27!2zMjUx5paw5YyX5biC5reh5rC05Y2A5Lit5bGx5YyX6Lev5LiJ5q61OTDomZ8!5e0!3m2!1szh-TW!2stw!4v1649669513959!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/Ex9gYvWbgBuNqBZK8',
  phone: '02-2626-8800',
  fbLink: 'https://www.facebook.com/105125832094346/',
  fbMessage: 'https://m.me/105125832094346',
  caseName: '文學苑',
  indigatorLength: 10,

  houseInfos: [
    ['投資興建', '鼎翊建設',],
    ['建築規劃', '劉文岱建築師',],
    ['建照號碼', '104淡建字第00616號',],
    ['行銷企劃', '澄果廣告',],
    ['經紀人', '胡光志（99）嘉市字第00074號',],
  ],

  gtmCode: ['NBPQ652'], // 可放置多個
  recaptcha_site_key_v2: '6LfGUjEaAAAAANYvhZQx5imGm23pRt0V-rOvkpNC',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '預約賞屋',
    subTitle: ''
  }
}
