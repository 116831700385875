export default [
  /* */
  { name: '文學苑', imgSrc: '', subTitle: '', section: 'section1', svgSrc: '',offset:0, mobileOffset: 0, type: '' },
  { name: '滿分宜居', imgSrc: '', subTitle: '', section: 'section2', svgSrc: '',offset:0, mobileOffset: 0 , type: '' },
  { name: '輕軌散步', imgSrc: '', subTitle: '', section: 'section3', svgSrc: '',offset:0, mobileOffset: 0, type: '' },
  { name: '行動力升級', imgSrc: '', subTitle: '', section: 'section4', svgSrc: '',offset:0, mobileOffset: 0, type: '' },
  { name: '菁英養成', imgSrc: '', subTitle: '', section: 'section5', svgSrc: '',offset:0, mobileOffset: 0 , type: '' },
  { name: '在地品牌', imgSrc: '', subTitle: '', section: 'section6', svgSrc: '',offset:0, mobileOffset: 0, type: '' },
  { name: '舒適尺度', imgSrc: '', subTitle: '', section: 'section7', svgSrc: '',offset:0, mobileOffset: 0, type: '' },
  { name: '安心建材', imgSrc: '', subTitle: '', section: 'section8', svgSrc: '',offset:0, mobileOffset: 0, type: '' },
 
  { name: '立即來電', imgSrc: '', subTitle: '', section: 'contact-info', svgSrc: '',offset:0, mobileOffset: 0, type: 'btn' },
  { name: '地圖導航', imgSrc: '', subTitle: '', section: 'google-map', svgSrc: '',offset:0, mobileOffset: 0, type: 'btn' },
  { name: '立即預約', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '',offset:0, mobileOffset: 0, type: 'btn' },
]